/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');


        //Attractions Background image
              var colDesktop = $('.c-attractions__image--left').data('desktop'),
              colMobile = $('.c-attractions__image--left').data('mobile');
              if(viewportWidth < 480 ){
                  $('.c-attractions__image--left').css('background-image', 'url('+colMobile+')');
              }else{
                  $('.c-attractions__image--left').css('background-image', 'url('+colDesktop+')');
              }
        ////////
        //Attractions Background image
              var colDesktop2 = $('.c-amenities__image--right').data('desktop'),
              colMobile2 = $('.c-amenities__image--right').data('mobile');
              if(viewportWidth < 480 ){
                  $('.c-amenities__image--right').css('background-image', 'url('+colMobile2+')');
              }else{
                  $('.c-amenities__image--right').css('background-image', 'url('+colDesktop2+')');
              }
        ////////

        //Activities Background image
        var colDesktop3 = $('.c-activities__image--left').data('desktop'),
        colMobile3 = $('.c-activities__image--left').data('mobile');
        if(viewportWidth < 480 ){
            $('.c-activities__image--left').css('background-image', 'url('+colMobile3+')');
        }else{
            $('.c-activities__image--left').css('background-image', 'url('+colDesktop3+')');
        }
        ////////

        //*Mobile Menu*//
          $( ".c-header__toggle" ).click(function(e) {
              e.preventDefault();

              $(".c-header__mobile").slideToggle();
          });
          // for the mobile animation

          $(".c-header__toggle").click(function(){
              $(this).toggleClass("is-active");
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
